import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/NOSSA-METODOLOGIA.png'
import Slider from '../components/SliderNossaMetodologia'
import { 
  section,
  HeaderBanner,
  container,
  content,
} from '../components/styles/Base.styles'

const NossaMetodologia = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Nossa Metodologia | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]}>
      <div css={container}>
        <h1>Nossa Metodologia</h1>
        <h2>Na Kingdom Kids, nos baseamos no Método Glenn Doman para a criação da nossa filosofia.</h2>
        <p>Acreditamos que, ao nascer, a criança já está pronta para ser estimulada. Seguindo essa premissa, nós temos espaços apropriados para a evolução cognitiva e completa do indivíduo.</p> 
        <p>Acolhemos muitas outras teorias que formam a Kingdom Kids. Sempre fizemos muito além de só dar banho, alimentar e colocar para dormir.</p>
        <p>Somos a primeira instituição de Ensino Infantil a oferecer um ambiente mais profundo no desenvolvimento humano!</p>
        <h2>Na Kingdom School, possuímos uma filosofia de trabalho ímpar.</h2>
        <p>Ensinamos de forma que não subestime a capacidade intelectual de cada um, onde não exista comparação entre eles. Devemos encará-los como seres únicos! Aqui, nossos alunos são desenvolvidos de forma que o cérebro seja constantemente estimulado de maneiras diferentes, permitindo que o interesse seja sempre despertado.</p>
        <p>O que nos move, é conseguir personalizar a educação individual mesmo que em um ambiente coletivo. As salas possuem uma estrutura ainda mais forte para que os alunos sejam sempre estimulados, evitando assim, um ensino monótono e desinteressante.</p>
        <p>Personalizamos a educação mesmo em um ambiente coletivo!</p>
        <div style={{ marginTop: 72 }}>
          <Slider />
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default NossaMetodologia
