import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import Img1 from '../images/new/banners/metodologia-1.jpg'
// import Img2 from '../images/new/banners/metodologia-2.jpg'
import Img3 from '../images/new/banners/metodologia-3.jpg'
// import Img4 from '../images/new/banners/metodologia-4.jpg'
import Img5 from '../images/new/banners/metodologia-5.jpg'
import Img6 from '../images/new/banners/metodologia-6.jpg'
import Img7 from '../images/new/banners/metodologia-7.jpg'

class VideosCarousel extends React.Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      autoplaySpeed: 6500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            dots: false
          }
        }
      ]
    }
    
    return (
      <Fragment>
        <Slider {...settings}>
          {/* <div>
            <img src={Img1} alt='' style={{ margin: 'auto' }} />
          </div>
          <div>
            <img src={Img2} alt='' style={{ margin: 'auto' }} />
          </div> */}
          <div>
            <img src={Img3} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          {/* <div>
            <img src={Img4} alt='' style={{ margin: 'auto' }} />
          </div> */}
          <div>
            <img src={Img5} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img6} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img7} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default VideosCarousel